import { Intl } from 'Roblox';
import classNames from 'classnames';
import React, { Dispatch, useMemo, useCallback } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Link, Button, IconButton, Popover, createSystemFeedback } from 'react-style-guide';
import { Thumbnail2d, ThumbnailTypes, ThumbnailUniverseThumbnailSize } from 'roblox-thumbnails';
import { groupsConfig } from '../translation.config';
import eventsService from '../services/eventsService';
import { VirtualEvent, GameDetails } from '../types';
import { Group } from '../../shared/types';
import GroupEventButton from './GroupEventButton';
import { logGroupPageClickEvent } from '../../shared/utils/logging';
import { EventContext } from '../../shared/constants/eventConstants';
import groupEventsConstants from '../constants/groupEventsConstants';
import AnalyticsEvents from '../utils/analytics';

const [SystemFeedback, systemFeedbackService] = createSystemFeedback();
const { locale } = new Intl();

export type GroupEventProps = {
  group: Group;
  event: VirtualEvent;
  gameDetails: GameDetails | undefined;
  featuredEventId: string | undefined;
  setFeaturedEventId: Dispatch<string | undefined>;
  canSetFeaturedEvent: boolean;
  isFullSized: boolean;
} & WithTranslationsProps;

const GroupEvent = ({
  group,
  event,
  gameDetails,
  featuredEventId,
  setFeaturedEventId,
  canSetFeaturedEvent,
  isFullSized,
  translate
}: GroupEventProps): JSX.Element | null => {
  const isFeaturedEvent = useMemo(() => {
    return featuredEventId === event.id;
  }, [featuredEventId, event.id]);

  const featureEvent = useCallback(async () => {
    try {
      if (isFeaturedEvent) {
        // Unfeature the event if it is already featured
        await eventsService.deleteFeaturedEvent(group.id, event.id);
        setFeaturedEventId(undefined);
        systemFeedbackService.success(translate('Message.UnfeatureEventSuccess'));
      } else {
        await eventsService.postFeaturedEvent(group.id, event.id);
        setFeaturedEventId(event.id);
        systemFeedbackService.success(translate('Message.FeatureEventSuccess'));
      }
      logGroupPageClickEvent({
        groupId: group.id,
        clickTargetType: isFeaturedEvent ? 'unfeatureEvent' : 'featureEvent',
        clickTargetId: event.id.toString(),
        context: EventContext.GroupHomepage
      });
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [isFeaturedEvent, setFeaturedEventId, group.id, event.id, translate]);

  const dateDescription = useMemo(() => {
    const date = new Date(Date.parse(event.eventTime.startUtc));
    const dayString = date.toLocaleString(locale, {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
    const timeString = date.toLocaleString(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return translate('Label.EventDate', {
      dayString,
      timeString
    });
  }, [event, translate]);

  const thumbnailTargetId = useMemo(() => {
    // If a thumbnail was uploaded with the event then use that, otherwise just use the thumbnail for the events game
    return event.thumbnails?.[0]?.mediaId || event.universeId;
  }, [event]);

  const thumbnailType = useMemo(() => {
    return event.thumbnails?.[0]?.mediaId
      ? ThumbnailTypes.assetThumbnail
      : ThumbnailTypes.universeThumbnail;
  }, [event]);

  const iconButtonOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // This is needed so opening the dropdown menu doesn't fire the event that links to the events page
    e.preventDefault();
    e.stopPropagation();
  };

  const trackEventDetailsPageVisit = useCallback(() => {
    AnalyticsEvents.sendEventDetailsPageVisitEvent(event.id, event.universeId);
  }, [event.id, event.universeId]);

  return (
    <Link
      aria-label={event.title}
      onClick={trackEventDetailsPageVisit}
      url={groupEventsConstants.urls.getEventUrl(event.id)}>
      <div className={classNames('section-content', 'group-event')}>
        <div className='group-event-thumbnail'>
          <Thumbnail2d
            type={thumbnailType}
            size={ThumbnailUniverseThumbnailSize.width768}
            targetId={thumbnailTargetId}
            containerClass='group-event-thumbnail-container'
          />
        </div>
        <div className='group-event-content'>
          <div className='group-event-header'>
            <h2>{event.title}</h2>
          </div>
          <div className='group-event-date text-default font-bold'>{dateDescription}</div>
          {isFullSized && (
            <div className='group-event-description text-description'>{event.description}</div>
          )}
          <div className='group-event-follow-button'>
            <GroupEventButton
              event={event}
              gameDetails={gameDetails}
              systemFeedbackService={systemFeedbackService}
            />
            {canSetFeaturedEvent && (
              <Popover
                id='group-event-dropdown-menu-popover'
                button={
                  <IconButton
                    className='group-event-feature-button'
                    iconName='more'
                    size={IconButton.sizes.small}
                    onClick={iconButtonOnClick}
                  />
                }
                trigger='click'
                placement='bottom'>
                <ul className='group-event-dropdown-menu dropdown-menu' role='menu'>
                  <li>
                    <Button variant={Button.variants.secondary} onClick={featureEvent}>
                      {isFeaturedEvent
                        ? translate('Action.UnfeatureEvent')
                        : translate('Action.FeatureEvent')}
                    </Button>
                  </li>
                </ul>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <SystemFeedback />
    </Link>
  );
};

export default withTranslations(GroupEvent, groupsConfig);
